import React, { useState, useEffect } from 'react';
import Footer from '../../micro-site/Components/footer/Footer';
import Loader from '../Loader';
import KheloHeader from 'micro-site/Components/KheloHeader/KheloHeader';

const LiveMatchVideos = () => {
  const [matches, setMatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // Fetch live matches
  const fetchLiveMatches = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await fetch(
        'https://dev-admin.satgsportsforlife.in/api/method/sfa_online.v1.live_matches.match.get_live_matches'
      );
      const data = await response.json();
      console.log('first api', data.message);
      setMatches(data.message || []);
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      console.error('Error fetching live matches:', error);
    }
  };

  useEffect(() => {
    fetchLiveMatches();
    const clientId = Math.floor(1000000 + Math.random() * 9000000);
    console.log('clientId--', clientId);
    const eventSource = new EventSource(
      `https://command-service-staging.sfaplay.com/command-panel/v1/media/sse?client_id=${clientId}`
    );

    console.log('eventSource--', eventSource);

    const handleSSEEvent = (type, data) => {
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (error) {
          console.error('Error parsing data:', error);
          return;
        }
      }
      if (type === 'FRAPPE_STARTED') {
        setMatches((prevMatches) => {
          const matchIndex = prevMatches.findIndex((match) => match.data.name === data.name);
          if (matchIndex !== -1) {
            const updatedMatches = [...prevMatches];
            updatedMatches[matchIndex] = { type, data };
            return updatedMatches;
          }
          return [...prevMatches, { type, data }];
        });
      } else if (type === 'FRAPPE_ENDED') {
        setMatches((prevMatches) => prevMatches.filter((match) => match.data.name !== data.name));
      }
    };

    eventSource.onmessage = (event) => {
      const { type, data } = JSON.parse(event.data);
      handleSSEEvent(type, data);
    };

    eventSource.onerror = (event) => {
      console.error('Error occurred:', event);
      eventSource.close();
    };
    console.log('eventSource', eventSource);
    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, []);

  return (
    <div className="w-screen">
      <KheloHeader />
      <section
        className="w-full flex flex-col"
        style={{ marginTop: 56, marginBottom: 56 }}
        id="live-matches"
      >
        <h3
          className="font-bold italic font-poppins text-center uppercase"
          style={{
            color: '#0D0D0D',
            fontSize: 24,
            marginBottom: 50,
          }}
        >
          Live Matches
        </h3>

        {/* Filters Row */}
        {/* {!isLoading && (
          <div className="flex flex-wrap items-center gap-4 mb-6 px-3 ml-16">
            <select
              className="border p-2 rounded-md flex-grow"
              style={{ maxWidth: "150px" }}
            >
              <option value="">Select Sport</option>
              <option value="badminton">Badminton</option>
              <option value="football">Football</option>
              <option value="cricket">Cricket</option>
            </select>
            <select
              className="border p-2 rounded-md flex-grow"
              style={{ maxWidth: "150px" }}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            <select
              className="border p-2 rounded-md flex-grow"
              style={{ maxWidth: "200px" }}
            >
              <option value="">Select Event Category</option>
              <option value="single">Single</option>
              <option value="double">Double</option>
            </select>
          </div>
        )} */}

        <div className="w-11/12 m-auto">
          <div
            className="grid grid-cols-1 md:grid-cols-3 justify-center gap-4 p-4 mx-auto"
            style={{ columnGap: 43, rowGap: 60 }}
          >
            {isLoading && (
              <div
                className="w-full flex items-center justify-center"
                style={{ marginLeft: '360px' }}
              >
                <Loader />
              </div>
            )}

            {isError && (
              <div className="text-center text-red-500">Failed to load live matches.</div>
            )}

            {!isLoading && matches.length > 0
              ? matches.map((match) => (
                  <div key={match.name} className="flex flex-col gap-3">
                    <iframe
                      width="100%"
                      height="250"
                      src={match.data.live_video_link}
                      title={`Live Match: ${match.data.sport_event}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                      style={{ borderRadius: '25px' }}
                    />
                    <p
                      className="font-poppins font-semibold text-center"
                      style={{
                        fontSize: 16,
                        color: '#000B27',
                      }}
                    >
                      {match.data.sport_event}
                    </p>
                  </div>
                ))
              : !isLoading && (
                  <div
                    className="w-full h-full flex justify-center items-center"
                    style={{ minHeight: '20px', marginLeft: '440px' }}
                  >
                    <div className="text-gray-500 text-center">Live matches are coming soon...</div>
                  </div>
                )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default LiveMatchVideos;
