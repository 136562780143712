import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import crossIcon from '../../../assets/img//Khelo Cross Hamburger.svg';

import { useUserProfile } from 'profiles/queries/profileHooks';
import { Popover, Transition } from '@headlessui/react';
import { logOut } from 'authentication/services/authentication';
import signOut from '../../../assets/img//log-out.svg';
import ProfileIcon from '../../../assets/img/user.svg';
import settingsIcon from '../../../assets/img/settings.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
// import { useAboutUsHeading } from 'micro-site/queries/hooks';
// import { ChevronDownIcon } from '@heroicons/react/solid';
import coachImage from 'assets/img/coach.png';
import profileImage from 'assets/img/Athlete.png';
import HeaderLogo from './HeaderLogo';
// import four from '../../../assets/img/cm-trophy-home-page/CM PHOTO.png';
// import five from '../../../assets/img/cm-trophy-home-page/image 259.png';
import header1 from '../../../assets/img/cm-trophy-home-page/header-1.png';
import header2 from '../../../assets/img/cm-trophy-home-page/header-2.png';
// import { ReactComponent as ArrowDown } from '../../../assets/img/cm-trophy-home-page/Alt Arrow Down.svg';
import SecondaryHeader from './SecondaryHeader';
// import four from '../../../assets/img/cm-trophy-home-page/CM PHOTO.png';
// import five from '../../../assets/img/cm-trophy-home-page/image 259.png';
import useCustomStyling from 'hooks/useCustomStyling';
import { TableIcon } from '@heroicons/react/solid';

const KheloHeader = ({ fromMicroSite, fromDigitalScoring, invisibleLinks, hideLogin }) => {
  const userProfileQuery = useUserProfile(undefined, !invisibleLinks);
  console.log(userProfileQuery)
  const match = useRouteMatch();
  const profileMatch = useRouteMatch('/:user/profiles/tournaments/:tournament_id');
  const microSiteMatch = useRouteMatch('/micro-site/:tournament_id/landing');

  const tournamentId = profileMatch?.params.tournament_id || microSiteMatch?.params?.tournament_id;

  const [openNavbar, setOpenNavbar] = useState(false);
  const [close2, setClose2] = useState();
  const [profileOpen, setProfileOpen] = useState(false);
  const { isMobile, isTablet } = useCustomStyling();

  const history = useHistory();

  const [subRouteOpen, setSubRouteOpen] = useState('');

  const MicroSiteroutes = [];
  const actualRoutes = typeof tournamentId !== 'undefined' ? MicroSiteroutes : [];
  const profileIcon = (data) => {
    if (!data?.message?.image && data?.message?.primary_role === 'coach') return coachImage;
    if (!data?.message?.image) return profileImage;
    return data?.message.image;
  };

  return (
    <>
      <div 
          className="bg-blue-kheloBlue400" 
          style={{
          backgroundImage: `url(${header1})`,
          backgroundSize: "70%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}>
        <div
            className="w-full h-20 flex items-center gap-2 shadow font-montserrat"

          style={{ minHeight: 160 }}
        >
          {/* part 1 */}
          <Link to={'/'}>
            <HeaderLogo />
          </Link>
          <div style={{ flex: isMobile ? '5' : '2' }}>
          <SecondaryHeader />
          </div>
          {/* part 3 */}
          <div className="flex items-center justify-end gap-2" style={{ flex: isMobile ? '2.5' : '1' }}>
            {/* {userProfileQuery.isSuccess ? (
              <>
               { (!isMobile && !isTablet) &&(
                <img
                  src={header2}
                  alt="Profile 2"
                  className="rounded-full object-contain"
                  style={{ width: isMobile ? '90px' : '150px', height: isMobile ? '40px' : '150px' }}
                />
               )}
                <Popover className="relative lg:mr-3.5">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={`
                      ${open ? '' : 'text-opacity-90'}
                      text-white group bg-orange-700 px-2 py-1.5 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                      >
                        <span
                          data-cy="user_icon"
                          onClick={
                            open
                              ? () => {
                                  close2 ? setClose2(false) : setClose2(true);
                                }
                              : () => setClose2(false)
                          }
                        >
                          <div
                            alt="icon"
                            style={{
                              borderRadius: '50%',

                              background: `url('${
                                userProfileQuery?.data?.message?.image ||
                                profileIcon(userProfileQuery?.data)
                              }')`,
                              backgroundColor: 'white',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                            }}
                            className="w-7 h-7 md:w-8 md:h-8 lg:w-10 lg:h-10"
                          />
                        </span>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          className={`absolute z-10 w-60 lg:w-72 px-4 transform -translate-x-1/2 -left-8 md:-left-20 lg:-left-24 sm:px-0 lg:max-w-3xl ${
                            close2 ? 'hidden' : 'block'
                          }`}
                        >
                          <div className="overflow-hidden rounded-lg shadow-lg">
                            <div
                              className="relative flex-col gap-8 bg-white p-4"
                              onClick={() => setClose2(true)}
                            >
                              <div
                                onClick={() =>
                                  history.push(
                                    `/${userProfileQuery?.data?.message?.primary_role}/profiles/profile`
                                  )
                                }
                                className={`flex items-center cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                              >
                                <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                                  <img
                                    src={ProfileIcon}
                                    className="mt-1"
                                    alt="logout"
                                    height="16px"
                                    width="16px"
                                  />
                                </div>
                                <div className="ml-4">
                                  <p
                                    data-cy="my_account_icon"
                                    className="text-sm mt-1  text-gray-900"
                                  >
                                    My Profile
                                  </p>
                                </div>
                              </div>
                              <div
                                onClick={() =>
                                  history.push(
                                    `/${userProfileQuery?.data?.message?.primary_role}/profiles/account-details`
                                  )
                                }
                                className={`flex hidden items-center cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                              >
                                <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                                  <img
                                    src={settingsIcon}
                                    className="mt-1"
                                    alt="logout"
                                    height="16px"
                                    width="16px"
                                  />
                                </div>
                                <div className="ml-4">
                                  <p
                                    data-cy="my_account_icon"
                                    className="text-sm mt-1 text-gray-900"
                                  >
                                    My Account
                                  </p>
                                </div>
                              </div>
                              <div
                                onClick={() => logOut(userProfileQuery?.data)}
                                className={`cursor-pointer flex items-center transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
                              >
                                <div className="flex items-center  justify-center flex-shrink-0 w-10 h-10 text-white ">
                                  <img
                                    src={signOut}
                                    className="mt-1"
                                    alt="logout"
                                    height="16px"
                                    width="16px"
                                  />
                                </div>
                                <div className="ml-4">
                                  <p className="text-sm mt-1 font-medium text-blue-kheloBlue">
                                    Logout
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </>
            ) : ( */}
              <div className="flex items-center justify-end gap-2 w-full">
                { (!isMobile) &&(
                <img
                  src={header2}
                  alt="Profile 2"
                  className="rounded-full object-contain"
                  style={{ width: '150px', height: '150px' }}
                />
                )}
                { (!isMobile) && window.location.pathname !== '/access-profile' && (
                <a
                  href="/access-profile"
                >
                  <button
                  style={{
                    height: isMobile ? '30px' : '56px',
                    width: isMobile ? '90px' : '200px',
                    backgroundColor: '#fff',
                    color: '#000',
                    fontSize: isMobile ? '9px' : '20px',
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                    border: 'none',
                    cursor: 'pointer',
                    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 10% 100%)',
                    paddingLeft: 9
                  }}
                >
                  Access Your Profile
                  <FontAwesomeIcon icon={faAngleDoubleRight} className="ml-1" />
                </button>
                </a>
              )}
              </div>
            {/* )} */}
          </div>
        </div>
      </div>
      <div
        className={`bg-white min-h-screen max-h-screen w-full fixed overflow-y-scroll top-0 z-40 lg:hidden ${
          !openNavbar ? '-right-full' : 'right-0'
        } transition-all duration-500`}
      >
        <div className="p-7 h-full">
          <div className="flex justify-between mb-8 overflow-y-auto">
            <div
              className="flex gap-4 items-center px-5 overflow-y-auto"
              onClick={() => {
                history.push(`/${userProfileQuery?.data?.message?.primary_role}/profiles/profile`);
                setOpenNavbar(false);
              }}
            >
              <div
                className="h-12 w-12 rounded-full flex-shrink-0 inline-block"
                style={{
                  backgroundImage: `url('${
                    userProfileQuery?.data?.message?.image || profileIcon(userProfileQuery?.data)
                  }')`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              ></div>
              <h1 className="text-sm font-bold text-blue-kheloBlue font-brand ">
                {(userProfileQuery?.isSuccess && userProfileQuery?.data?.message?.full_name) ||
                  'Guest User'}
              </h1>
            </div>
            <img src={crossIcon} onClick={() => setOpenNavbar(false)} alt={''} />
          </div>

          <div className=" bg-gray-900 h-px my-8"></div>
          <div className=" flex flex-col gap-8">
            {actualRoutes.map((route, i) =>
              i ? (
                !route.subRoutes ? (
                  route.external === false ? (
                    <Link
                      onClick={() => setOpenNavbar(false)}
                      key={i}
                      to={
                        route.route === '%22How%20To%22%20Guide'
                          ? 'gallery/%22How%20To%22%20Guide'
                          : !route.ignoreBaseRoute
                          ? `${match.url}/landing/${route.route}`
                          : route.route
                      }
                      className="text-secondary-khelo text-l  font-bold font-brand ignore-style"
                    >
                      {route.name}
                    </Link>
                  ) : (
                    <Link
                      to={{ pathname: route.route }}
                      target="_SEJ"
                      rel="noreferrer"
                      className="text-secondary-khelo text-l  font-bold font-brand ignore-style"
                    >
                      {route.name}
                    </Link>
                  )
                ) : (
                  <React.Fragment key={i}>
                    <div
                      role="button"
                      className="text-secondary-khelo text-l  font-bold font-brand "
                      onClick={() =>
                        setSubRouteOpen((prev) => {
                          if (prev !== route.name) {
                            setSubRouteOpen(route.name);
                          } else setSubRouteOpen('');
                        })
                      }
                    >
                      {route.name}
                      <FontAwesomeIcon icon={faCaretDown} className="ml-1" />
                    </div>
                    <div
                      className={`ml-3 -mt-5 ${subRouteOpen === route.name ? 'block' : 'hidden'}`}
                    >
                      {route.subRoutes.map((el, i) => (
                        <div
                          role="button"
                          key={i}
                          className="text-secondary-khelo text-md mt-2 font-semibold font-brand "
                          onClick={() => {
                            setOpenNavbar(false);
                            setSubRouteOpen((prev) => {
                              if (prev !== route.name) {
                                setSubRouteOpen(route.name);
                              } else setSubRouteOpen('');
                            });
                            history.push(
                              !el.ignoreBaseRoute ? `${match.url}/landing/${el.route}` : el.route
                            );
                          }}
                        >
                          {el.name}
                        </div>
                      ))}
                    </div>
                  </React.Fragment>
                )
              ) : (
                <React.Fragment key={i}>
                  <Link
                    onClick={() => setOpenNavbar(false)}
                    key={i}
                    to={
                      !route.ignoreBaseRoute ? `${match.url}/landing/${route.route}` : route.route
                    }
                    className="text-secondary-khelo text-l  font-bold font-brand ignore-style"
                  >
                    {route.name}
                  </Link>
                  {userProfileQuery.isSuccess && (
                    <>
                      {userProfileQuery?.data?.message?.primary_role !== 'dashboard_viewer' ? (
                        <div
                          role="button"
                          className="text-secondary-khelo text-l  font-bold font-brand "
                          onClick={() =>
                            setProfileOpen((prevData) => {
                              return !prevData;
                            })
                          }
                        >
                          Profile
                          <FontAwesomeIcon icon={faCaretDown} className="ml-1" />
                        </div>
                      ) : (
                        <div
                          className="text-secondary-khelo text-l  font-bold font-brand "
                          onClick={() => {
                            setOpenNavbar(false);
                            history.push(
                              `/${userProfileQuery.data.message.primary_role}/profiles/profile`
                            );
                          }}
                        >
                          Profile
                        </div>
                      )}
                      <div className={`ml-3 -mt-5 ${profileOpen ? 'block' : 'hidden'}`}>
                        <div
                          role="button"
                          className="text-secondary-khelo text-md mt-2 font-semibold font-brand "
                          onClick={() => {
                            setOpenNavbar(false);
                            setProfileOpen((prevData) => {
                              return !prevData;
                            });
                            history.push(
                              `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/${tournamentId}/match-fixtures-results`
                            );
                          }}
                        >
                          Matches
                        </div>
                        {userProfileQuery?.data?.message?.primary_role !== 'institution' ? (
                          <>
                            <div
                              role="button"
                              className="text-secondary-khelo text-md mt-2 font-semibold font-brand "
                              onClick={() => {
                                setOpenNavbar(false);
                                setProfileOpen((prevData) => {
                                  return !prevData;
                                });
                                history.push(
                                  `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/${tournamentId}/accomodation`
                                );
                              }}
                            >
                              Arrival/Stay
                            </div>
                            <div
                              role="button"
                              className="text-secondary-khelo text-md mt-2 font-semibold font-brand "
                              onClick={() => {
                                setOpenNavbar(false);
                                setProfileOpen((prevData) => {
                                  return !prevData;
                                });
                                history.push(
                                  `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/${tournamentId}/transport`
                                );
                              }}
                            >
                              Food/Travel
                            </div>
                            <div
                              role="button"
                              className="text-secondary-khelo text-md mt-2 font-semibold font-brand "
                              onClick={() => {
                                setOpenNavbar(false);
                                setProfileOpen((prevData) => {
                                  return !prevData;
                                });
                                history.push(
                                  `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/${tournamentId}/accreditation`
                                );
                              }}
                            >
                              Accred & Kit
                            </div>
                          </>
                        ) : null}
                        {userProfileQuery?.data?.message?.primary_role === 'coach' && (
                          <>
                            <div
                              role="button"
                              className="text-secondary-khelo text-md mt-2 font-semibold font-brand "
                              onClick={() => {
                                setOpenNavbar(false);
                                setProfileOpen((prevData) => {
                                  return !prevData;
                                });
                                history.push(
                                  `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/${tournamentId}/contingents`
                                );
                              }}
                            >
                              Contingents
                            </div>
                            <div
                              role="button"
                              className="text-secondary-khelo text-md mt-2 font-semibold font-brand "
                              onClick={() => {
                                setOpenNavbar(false);
                                setProfileOpen((prevData) => {
                                  return !prevData;
                                });
                                history.push(
                                  `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/${tournamentId}/events`
                                );
                              }}
                            >
                              My Teams
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </React.Fragment>
              )
            )}

            {userProfileQuery.isSuccess ? (
              <div
                role="button"
                className="text-secondary-khelo text-l  font-bold font-brand "
                onClick={() => logOut(userProfileQuery?.data)}
              >
                Log Out
              </div>
            ) : (
              // <div
              //   className="text-secondary-khelo text-l  font-bold font-brand "
              //   onClick={() => history.push('/signin')}
              // >
              //   Sign In
              // </div>
              <span></span>
            )}
          </div>
          <div className=" bg-gray-900 h-px my-8"></div>
          <div className="flex gap-4 justify-center font-medium">
            <a
              href="/files/(SAG) Privacy Policy Draft for NGbe3687.pdf"
              target="_blank"
              rel="noreferrer"
              className="text-blue-kheloBlue text-xs underline font-brand "
            >
              Privacy Policy
            </a>
            {/* <div className="text-blue-kheloBlue text-xs underline font-brand ">
              Terms & Conditions
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default KheloHeader;
